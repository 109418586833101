import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { CustomerAddressDialogComponent } from 'src/app/components/customer-page/customer-address-dialog/customer-address-dialog.component';
import { CustomerAddress, Customers } from 'src/app/models/customer';
import { CustomerAddressService } from 'src/app/services/customer-address.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Guid } from '../../generic/generic.component';
import { CustomerSettingDialogComponent } from '../customer-setting-dialog/customer-setting-dialog.component';

const getAddressType = (type) => {
  switch (type) {
    case 0:
      return 'Production Shipment';
    case 1:
      return 'Billing';
    case 2:
      return 'Development Shipment';
    default:
      return '';
  }
};

const addressesTypeField = {
  0: 'productionAddresses',
  1: 'billingAddresses',
  2: 'developmentAddresses',
};

@Component({
  selector: 'customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.scss'],
})
export class CustomerDialogComponent implements OnInit {
  customer: Customers;
  imageFiles: FileList | null = null;
  isSaving: boolean = false;
  addressColumns = ['address', 'phone', 'action'];
  reloadList: boolean = false;

  selectedAddress: CustomerAddress = null;

  constructor(
    public dialogRef: MatDialogRef<CustomerDialogComponent>,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private customerService: CustomerService,
    private customerAddressService: CustomerAddressService,
    @Inject(MAT_DIALOG_DATA)
    public props: { customer: Customers, edit: boolean },
  ) {}

  ngOnInit(): void {
    const { billingAddresses, developmentAddresses, productionAddresses } =
      this.props.customer?.addresses?.reduce(
        (accum, address) => {
          address.fullAddress = this.formatAddress(address);
          address.addressTypeLabel = getAddressType(address.addressType);
          switch (address.addressType) {
            case 0:
              accum.productionAddresses.push(address);
              break;
            case 1:
              accum.billingAddresses.push(address);
              break;
            case 2:
              accum.developmentAddresses.push(address);
              break;
          }
          return accum;
        },
        {
          billingAddresses: [] as CustomerAddress[],
          developmentAddresses: [] as CustomerAddress[],
          productionAddresses: [] as CustomerAddress[],
        },
      ) ?? {
        billingAddresses: [] as CustomerAddress[],
        developmentAddresses: [] as CustomerAddress[],
        productionAddresses: [] as CustomerAddress[],
      };
    this.customer = {
      ...this.props.customer,
      billingAddresses,
      developmentAddresses,
      productionAddresses,
    };
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onUploadChange(files: FileList) {
    this.imageFiles = files;
    this.customer.picPath = URL.createObjectURL(files[0]);
  }

  uploadImage() {
    const formData = new FormData();
    for (var i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }
    const fileId = Guid.newGuid();
    formData.append('id', fileId);
    this.isSaving = true;
    this.customerService.uploadTempPhoto(formData).subscribe((response) => {
      if (response.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' +
            Math.round((100 * response.loaded) / response.total) +
            '%',
        );
      } else if (response.type === HttpEventType.Response) {
        this.customerService
          .moveTemp(this.customer.custIdno, fileId)
          .subscribe((upload) => {
            this.customer.picPath = upload.responseObject;
            this.customerService.post(this.customer, '').subscribe((result) => {
              this.isSaving = false;
              this.dialogRef.close(true);
            });
          });
      }
    });
  }

  uploadPicture() {
    const formData = new FormData();
    for (var i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }
    formData.append('customerId', this.customer.custIdno.toString());
    this.isSaving = true;
    this.customerService.uploadPicture(formData).subscribe((response) => {
      if (response.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' +
            Math.round((100 * response.loaded) / response.total) +
            '%',
        );
      } else if (response.type === HttpEventType.Response) {
        this.isSaving = false;
        this.dialogRef.close(true);
      }
    });
  }

  onSave() {
    this.isSaving = true;
    const {
      billingAddresses,
      developmentAddresses,
      productionAddresses,
      ...customer
    } = this.customer;
    const addresses = [
      ...billingAddresses,
      ...developmentAddresses,
      ...productionAddresses,
    ];
    this.customerService.post(customer, '').subscribe((result) => {
      this.customer.custIdno = result.custIdno;
      if (addresses.length) {
        this.onSaveAddresses(this.customer.custIdno, addresses).subscribe(
          () => {
            if (this.imageFiles?.length) {
              this.uploadPicture();
              return;
            }
            this.isSaving = false;
            this.dialogRef.close(true);
          },
        );
        return;
      }
      if (this.imageFiles?.length) {
        this.uploadPicture();
        return;
      }
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }

  onSaveAddresses(custIdNo, addresses) {
    return this.customerAddressService.setCustomerAddresses(
      custIdNo,
      addresses,
    );
  }

  selectAddress(
    address = {
      tempCustomersAddressesId: DateTime.now().valueOf(),
    } as CustomerAddress,
  ) {
    this.selectedAddress = address;
  }

  formatAddress(address: CustomerAddress) {
    const address1 = address.address1 ? `${address.address1} ` : '';
    const address2 = address.address2 ? `${address.address2} ` : '';
    const city = address.city ? `${address.city}, ` : '';
    const region = address.region ? `${address.region} ` : '';
    const postalCode = address.postalCode ? `${address.postalCode}, ` : '';
    const country = address.country ? `${address.country}` : '';

    return `${address1}${address2}${city}${region}${postalCode}${country}`;
  }

  onAddAddress() {
    this.selectedAddress.fullAddress = this.formatAddress(this.selectedAddress);
    this.selectedAddress.addressTypeLabel = getAddressType(
      this.selectedAddress.addressType,
    );
    const addressField =
      addressesTypeField[this.selectedAddress.addressType] ?? 'addresses';

    const selectedIndex = this.customer[addressField].findIndex((a) =>
      this.selectedAddress.customersAddressesId
        ? a.customersAddressesId === this.selectedAddress.customersAddressesId
        : a.tempCustomersAddressesId ===
          this.selectedAddress.tempCustomersAddressesId,
    );

    if (selectedIndex >= 0) {
      this.customer[addressField][selectedIndex] = this.selectedAddress;
    } else {
      this.customer[addressField] = [
        ...(this.customer[addressField] ?? []),
        this.selectedAddress,
      ];
    }
    this.selectedAddress = null;
  }
  onCancelAddress() {
    this.selectedAddress = null;
  }
  isDefault(address: CustomerAddress) {
    if (address.isDefault) return true;
    if (!address.customersAddressesId) return false;
    switch (address.addressType) {
      case 0:
        return (
          address.customersAddressesId === this.customer.defaultProdShipAddress
        );
      case 1:
        return (
          address.customersAddressesId === this.customer.defaultBillAddress
        );
      case 2:
        return (
          address.customersAddressesId === this.customer.defaultDevShipAddress
        );
      default:
        return false;
    }
  }
  onSetDefault(address: CustomerAddress) {
    if (!this.props.edit) return;
    const addressType = {
      0: 'defaultProdShipAddress',
      1: 'defaultBillAddress',
      2: 'defaultDevShipAddress',
    };
    const key = addressType[address.addressType];
    this.customer[key] = address.customersAddressesId ?? 0;
    if (!address.customersAddressesId) {
      const field = addressesTypeField[address.addressType] ?? 'addresses';
      this.customer[field] = this.customer[field].map((a) => ({
        ...a,
        isDefault:
          address.addressType !== a.addressType
            ? a.isDefault
            : a.tempCustomersAddressesId === address.tempCustomersAddressesId,
      }));
      return;
    }

    this.customerAddressService
      .setAddressDefault({
        customerId: this.customer.custIdno,
        defaultProdShipAddress: this.customer.defaultProdShipAddress ?? 0,
        defaultDevShipAddress: this.customer.defaultDevShipAddress ?? 0,
        defaultBillAddress: this.customer.defaultBillAddress ?? 0,
      })
      .subscribe();
  }

  onOpenAddressDialog(addressType, customerAddress = null) {
    const dialogRef = this.dialog.open(CustomerAddressDialogComponent, {
      disableClose: true,
      maxWidth: '700px',
      width: '100%',
      autoFocus: false,
      data: {
        customerId: 0,
        addressType,
        customerAddress,
      },
    });
    dialogRef.afterClosed().subscribe((customerAddress) => {
      this.selectedAddress = customerAddress;
      this.onAddAddress();
    });
  }

  onOpenSettingDialog() {
    const dialogRef = this.dialog.open(CustomerSettingDialogComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      autoFocus: false,
      data: { customer: this.customer },
    });
    dialogRef.afterClosed().subscribe((customer: Customers | string) => {
      if (typeof customer !== 'string' && customer?.custIdno > 0) {
        this.reloadList = true;
        this.customer.hwDisclaimFlag = customer.hwDisclaimFlag;
        this.customer.dontAllowMixedInvoices = customer.dontAllowMixedInvoices;
      } else {
        this.reloadList = false
      }
    })
  }

  onClose() {
    this.dialogRef.close(this.reloadList);
  }
}
