import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';

@Component({
  templateUrl: './product-setting-dialog.component.html',
  styleUrls: ['./product-setting-dialog.component.scss'],
})
export class ProductSettingDialogComponent implements OnInit {
  isLoading = false;
  isSaving = false;
  productSetting = {
    workOrderLimit: 0,
    isBundled: false,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { productId: number; editMode: boolean },
    public dialogRef: MatDialogRef<ProductSettingDialogComponent>,
    private productsService: ProductService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.productsService.getProductWorkOrderLimit(this.data.productId),
      this.productsService.getProductBundled(this.data.productId),
    ]).subscribe(([{ workOrderLimit }, { isBundled }]) => {
      this.productSetting.workOrderLimit = workOrderLimit ?? 0;
      this.productSetting.isBundled = isBundled ?? false;
      this.isLoading = false;
    });
  }

  onSave() {
    this.isSaving = true;
    forkJoin([
      this.productsService.updateProductWorkOrderLimit({
        productId: this.data.productId,
        workOrderLimit: this.productSetting.workOrderLimit,
      }),
      this.productsService.updateProductBundle(
        this.data.productId,
        this.productSetting.isBundled,
      ),
    ]).subscribe(() => {
      this.onCloseDialog();
    });
  }

  setProductAsBundle() {
    if (!this.data.editMode) return;
    this.productSetting.isBundled = !this.productSetting.isBundled;
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
}
