<div class="product-setting-dialog">
  <h3 mat-dialog-title>Product Settings</h3>
  <mat-dialog-content class="mat-typography product-setting-dialog__container">
    <mat-form-field class="search-input" appearance="fill" style="width: 100%">
      <mat-label>Work Order Limit</mat-label>
      <input
        matInput
        type="number"
        min="0"
        [(ngModel)]="productSetting.workOrderLimit"
        [disabled]="!data.editMode"
      />
    </mat-form-field>
    <div style="display: flex; align-items: center; font-size: 14px">
      <div style="margin-right: 15px">
        <nz-switch
          [(ngModel)]="productSetting.isBundled"
          [nzCheckedChildren]="checkedAllowedTemplate"
          [nzUnCheckedChildren]="unCheckedAllowedTemplate"
          [disabled]="!data.editMode"
        ></nz-switch>
        <ng-template #checkedAllowedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedAllowedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
      </div>
      <div (click)="setProductAsBundle()" style="cursor: pointer">
        Bundled Product
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 10px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onSave()"
      [disabled]="isSaving || isLoading || !data.editMode"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
