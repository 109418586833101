import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { OpenOrder } from 'src/app/models/orders.model';
import { OrderService } from 'src/app/services/order.service';
import { WorkOrderPrintDialogComponent } from '../work-order-view/work-order-print/work-order-print-dialog.component';
interface Filters {
  ReceivedFrom: string;
  ReceivedTo: string;
  DueDateFrom: string;
  DueDateTo: string;
  CustomerId: number;
  ShowOverdueOnly: boolean;
  DueCutOff: 'all' | 'today' | 'tomorrow-earlier' | 'next-week-earlier';
  OrderType: number[];
}
interface OpenOrderModel {
  productsId: number;
  productName: string;
  type: string;
  picturePath: string;
  totalBackOrder: number;
  items: OpenOrder[];
}

@Component({
  selector: 'open-order-report',
  templateUrl: './open-order-report.component.html',
  styleUrls: ['./open-order-report.component.scss'],
})
export class OpenOrderReportComponent implements OnInit {
  customerList: Array<{ customerId: number; customerName: string }>;
  filter: Filters = {
    CustomerId: 0,
    ReceivedFrom: DateTime.local().minus({ months: 9 }).toFormat('yyyy-MM-dd'),
    ReceivedTo: DateTime.local().toFormat('yyyy-MM-dd'),
    DueDateFrom: null,
    DueDateTo: null,
    ShowOverdueOnly: false,
    DueCutOff: 'all',
    OrderType: [-1],
  } as Filters;
  sortBy: string = 'customername';
  sortDirection: string = 'asc';
  openOrders: OpenOrderModel[] = [];
  totalBackOrder = 0;
  orders: OpenOrder[] = [];
  isLoading = true;
  rawData: OpenOrder[] = [];
  orderTypeControl = new FormControl([-1]);
  orderTypePreviousValues: number[] = [0];
  private debouncer = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private userPermissionService: UserPermissionService,
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.openOrderReport);

    this.debouncer.pipe(debounceTime(1000)).subscribe(() => {
      this.filter.OrderType = this.orderTypeControl.value;
      this.getOpenOrders();
    });
  }

  ngOnInit(): void {
    this.getOpenOrders(true);
  }

  onDateRangeChange({ start, end }) {
    this.filter.ReceivedFrom = start;
    this.filter.ReceivedTo = end;
    this.getOpenOrders();
  }

  getOpenOrders(isInitial = false) {
    this.orderService
      .getOpenOrder({
        CustomerId: this.filter.CustomerId,
        FromDate: this.filter.ReceivedFrom,
        ToDate: this.filter.ReceivedTo,
        OrderBy: this.sortBy,
        SortDirection: this.sortDirection,
        ShowOverdueOnly: this.filter.ShowOverdueOnly,
        OrderType: this.filter.OrderType,
      })
      .subscribe(({ data, customers }) => {
        if (isInitial) {
          this.customerList = orderBy(customers, 'companyName');
        }
        this.isLoading = false;
        this.rawData = [...data];
        this.onChangeDueCutOff();
      });
  }

  onSortChange() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.getOpenOrders();
  }

  onParseOpenOrders(orders: OpenOrder[]) {
    this.openOrders = orders.reduce<OpenOrderModel[]>((accum, order) => {
      const productIndex = accum.findIndex(
        (i) => i.productsId === order.productsId && i.type === order.type,
      );

      if (productIndex >= 0) {
        const productDetail = accum[productIndex];
        order.link = `/main/orders/${order.ordersId}`;
        order.materialPrintColor1Hex = `#${order.materialPrintColor1}`;
        productDetail.items.push(order);
        productDetail.totalBackOrder =
          productDetail.totalBackOrder + (order.backOrder ?? 0);
        accum[productIndex] = productDetail;
      } else {
        const productDetail = {} as OpenOrderModel;
        order.link = `/main/orders/${order.ordersId}`;
        order.materialPrintColor1Hex = `#${order.materialPrintColor1}`;
        productDetail.productName = order.productName;
        productDetail.picturePath = order.picturePath;
        productDetail.items = [order];
        productDetail.totalBackOrder = order.backOrder ?? 0;
        productDetail.productsId = order.productsId;
        productDetail.type = order.type;
        accum.push(productDetail);
      }
      return accum;
    }, []);
    this.totalBackOrder = this.openOrders.reduce(
      (total, o) => total + o.totalBackOrder,
      0,
    );
  }

  onChangeDueDate({ start, end }) {
    this.filter.DueDateFrom = start;
    this.filter.DueDateTo = end;
    this.onChangeDueCutOff();
  }

  onSelectionChange() {
    const currentValue = this.orderTypeControl.value;
    let values = currentValue;
    const currentSelection = currentValue.find(
      (v) => !this.orderTypePreviousValues.includes(v),
    );

    if (currentSelection === -1 || currentValue?.length === 0) {
      values = [-1];
    } else if (currentValue?.length > 1) {
      values = currentValue.filter((o) => o !== -1);
    }
    this.orderTypeControl.setValue(values);
    this.orderTypePreviousValues = values;
    this.debouncer.next();
  }

  onChangeDueCutOff() {
    const dateFilter = DateTime.fromISO(this.filter.DueDateTo);
    const data =
      this.filter.DueDateTo == null
        ? [...this.rawData]
        : this.rawData.filter((d) => {
            const dueDate = DateTime.fromISO(d.dueDate.split('T')?.[0]);
            return dueDate <= dateFilter;
          });
    this.onParseOpenOrders([...data]);
  }

  onPrintWorkOrder(order: OpenOrder) {
    if (order.workOrdersId <= 0) return;
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: order.printedDate,
          releasedDate: order.releasedDate,
          dateClosed: order.dateClosed,
          workOrdersId: order.workOrdersId,
        },
      },
    });
  }

  onPrint() {
    window.print();
  }
}
