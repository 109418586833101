import { PAGE_NAME } from './pages';

export const ORDER_DETAILS_FEATURE_KEY = {
  saveButton: 'ORDER_DETAILS_SAVE_BUTTON',
  commentField: 'ORDER_DETAILS_COMMENT_FIELD',
  shippingField: 'ORDER_DETAILS_SHIPPING_FIELD',
  billingField: 'ORDER_DETAILS_BILLING_FIELD',
  internalPoField: 'ORDER_DETAILS_INTERNAL_PO_FIELD',
  customerField: 'ORDER_DETAILS_CUSTOMER_FIELD',
  receivedDateField: 'ORDER_DETAILS_RECEIVED_DATE_FIELD',
  entryDateField: 'ORDER_DETAILS_ENTRY_DATE_FIELD',
  dueDateField: 'ORDER_DETAILS_DUE_DATE_FIELD',
  externalPoField: 'ORDER_DETAILS_EXTERNAL_PO_FIELD',
  generateWorkOrder: 'ORDER_DETAILS_GENERATE_WORK_ORDER_FIELD',
  addOrderDetail: 'ORDER_DETAILS_ADD_ORDER_DETAIL_FIELD',
  updateDetail: 'ORDER_DETAILS_UPDATE_DETAIL_FIELD',
  deleteDetail: 'ORDER_DETAILS_DELETE_DETAIL_FIELD',
  printDetail: 'ORDER_DETAILS_PRINT_DETAIL_FIELD',
  clearDetail: 'ORDER_DETAILS_CLEAR_DETAIL',
  byPassWorkOrder: 'ORDER_DETAILS_BY_PASS_WORK_ORDER',
  deleteOrder: 'ORDER_DETAILS_DELETE',
};

export const orderDetailsPermission = {
  [PAGE_NAME.orderDetails]: {
    SuperUser: {
      [ORDER_DETAILS_FEATURE_KEY.saveButton]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.commentField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.shippingField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.billingField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.internalPoField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.customerField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.receivedDateField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.entryDateField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.dueDateField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.externalPoField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.generateWorkOrder]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.addOrderDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.updateDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.deleteDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.printDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.clearDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.byPassWorkOrder]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.deleteOrder]: 'access',
    },
    User: {
      [ORDER_DETAILS_FEATURE_KEY.saveButton]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.commentField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.shippingField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.billingField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.internalPoField]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.customerField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.receivedDateField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.entryDateField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.dueDateField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.externalPoField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.generateWorkOrder]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.addOrderDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.updateDetail]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.deleteDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.printDetail]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.clearDetail]: 'hidden',
      [ORDER_DETAILS_FEATURE_KEY.byPassWorkOrder]: 'access',
      [ORDER_DETAILS_FEATURE_KEY.deleteOrder]: 'access',
    },
    Viewer: {
      [ORDER_DETAILS_FEATURE_KEY.saveButton]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.commentField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.shippingField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.billingField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.internalPoField]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.generateWorkOrder]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.addOrderDetail]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.updateDetail]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.deleteDetail]: 'hidden',
      [ORDER_DETAILS_FEATURE_KEY.printDetail]: 'disabled',
      [ORDER_DETAILS_FEATURE_KEY.clearDetail]: 'hidden',
      [ORDER_DETAILS_FEATURE_KEY.byPassWorkOrder]: 'hidden',
      [ORDER_DETAILS_FEATURE_KEY.deleteOrder]: 'hidden',
    },
  },
};
