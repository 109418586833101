<div class="customer-dialog-header">
  <h3 mat-dialog-title style="margin-bottom: 0">Customers</h3>

  <button
    class="riva-button"
    *ngIf="props.edit"
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      min-width: unset;
      height: 45px;
    "
    (click)="onOpenSettingDialog()"
  >
    <i nz-icon nzType="setting" nzTheme="outline" style="font-size: 18px"></i>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <div class="customer-dialog">
    <div class="detail">
      <div>
        <div class="customer-dialog__image-file">
          <div
            *ngIf="!customer.picPath"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 70px;
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-photo"
              width="44"
              height="44"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="15" y1="8" x2="15.01" y2="8" />
              <rect x="4" y="4" width="16" height="16" rx="3" />
              <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
              <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
            </svg>
            No Image
          </div>
          <div
            class="customer-dialog__image-container"
            *ngIf="customer.picPath"
          >
            <img
              style="max-width: 100%; max-height: 100%"
              [src]="sanitize(customer.picPath)"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
          <input
            type="file"
            #uploadImage
            style="display: none"
            accept="image/*"
            multiple
            (change)="onUploadChange(uploadImage.files)"
          />
          <button
            class="riva-button"
            *ngIf="props.edit"
            (click)="uploadImage.click()"
            style="display: flex; align-items: center; justify-content: center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-upload"
              width="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              style="margin-right: 10px"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
              <polyline points="7 9 12 4 17 9" />
              <line x1="12" y1="4" x2="12" y2="16" />
            </svg>
            Upload
          </button>
        </div>
      </div>
      <div>
        <fieldset [disabled]="!props.edit">
          <div class="two-column">
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Customer ID</mat-label>
                <input matInput [(ngModel)]="customer.customerId" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Company Name</mat-label>
                <input matInput [(ngModel)]="customer.companyName" />
              </mat-form-field>
            </div>
          </div>
          <div class="two-column">
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Contact Name</mat-label>
                <input matInput [(ngModel)]="customer.contactName" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Email Address</mat-label>
                <input matInput [(ngModel)]="customer.emailAddress" />
              </mat-form-field>
            </div>
          </div>
          <div class="two-column">
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Lead Time (Days)</mat-label>
                <input
                  matInput
                  appNumberOnly
                  [(ngModel)]="customer.leadtimeDays"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Payment Terms</mat-label>
                <input matInput [(ngModel)]="customer.paymentTerms" />
              </mat-form-field>
            </div>
          </div>
          <div class="two-column">
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Preferred Shipping</mat-label>
                <input matInput [(ngModel)]="customer.preferredShipping" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Shipping Account Number</mat-label>
                <input matInput [(ngModel)]="customer.shippingAccountNumber" />
              </mat-form-field>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="customer-address-container">
      <div style="margin-bottom: 10px">
        <div class="selected-address-container" *ngIf="selectedAddress">
          <div class="two-column">
            <mat-form-field style="width: 100%" appearance="fill">
              <mat-label>Address 1</mat-label>
              <input matInput [(ngModel)]="selectedAddress.address1" />
            </mat-form-field>
            <mat-form-field style="width: 100%" appearance="fill">
              <mat-label>Address 2</mat-label>
              <input matInput [(ngModel)]="selectedAddress.address2" />
            </mat-form-field>
          </div>
          <div class="three-column">
            <mat-form-field style="width: 100%" appearance="fill">
              <mat-label>City</mat-label>
              <input matInput [(ngModel)]="selectedAddress.city" />
            </mat-form-field>
            <mat-form-field style="width: 100%" appearance="fill">
              <mat-label>Region</mat-label>
              <input matInput [(ngModel)]="selectedAddress.region" />
            </mat-form-field>
            <mat-form-field style="width: 100%" appearance="fill">
              <mat-label>Postal Code</mat-label>
              <input matInput [(ngModel)]="selectedAddress.postalCode" />
            </mat-form-field>
          </div>
          <div class="three-column">
            <mat-form-field style="width: 100%" appearance="fill">
              <mat-label>Country</mat-label>
              <input matInput [(ngModel)]="selectedAddress.country" />
            </mat-form-field>
            <mat-form-field style="width: 100%" appearance="fill">
              <mat-label>Phone</mat-label>
              <input matInput [(ngModel)]="selectedAddress.phone" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Address Type</mat-label>
              <mat-select [(value)]="selectedAddress.addressType">
                <mat-option [value]="0">Production Shipment</mat-option>
                <mat-option [value]="2">Development Shipment</mat-option>
                <mat-option [value]="1">Billing</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="address-form-action">
            <button class="btn btn-default" (click)="onCancelAddress()">
              Cancel
            </button>
            <button class="btn btn-success btn-sm" (click)="onAddAddress()">
              Add
            </button>
          </div>
        </div>
      </div>
      <table>
        <tr class="mat-row">
          <td colspan="3" class="mat-cell address-header-row">
            <div class="address-header">
              <div>Billing Address</div>
              <div>
                <button
                  class="riva-button riva-button-sm"
                  style="width: 110px"
                  *ngIf="props.edit && !selectedAddress"
                  (click)="onOpenAddressDialog(1)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-plus"
                    width="15"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#FFFFFF"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style="margin-right: 3px"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="12" y1="5" x2="12" y2="19" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                  New
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr class="mat-row" *ngFor="let element of customer.billingAddresses">
          <td class="mat-cell">
            {{ element.fullAddress }}
            <span style="display: inline-block; padding-right: 5px">{{
              element.addressTypeLabel
            }}</span>
            <span *ngIf="isDefault(element)" class="set-default-button"
              >default</span
            >
            <span
              class="default-address"
              *ngIf="!isDefault(element)"
              (click)="onSetDefault(element)"
              >Set Default</span
            >
          </td>
          <td class="mat-cell">{{ element.phone }}</td>
          <td class="mat-cell">
            <div class="action-button" *ngIf="props.edit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-edit"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                (click)="onOpenAddressDialog(1, element)"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                />
                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                <line x1="16" y1="5" x2="19" y2="8" />
              </svg>
            </div>
          </td>
        </tr>
        <tr class="mat-row" *ngIf="!customer.billingAddresses.length">
          <td class="mat-cell" colspan="3" style="text-align: center">
            No Billing Address
          </td>
        </tr>

        <tr class="mat-row">
          <td colspan="3" class="mat-cell address-header-row">
            <div class="address-header">
              <div>Production Shipping Address</div>
              <div>
                <button
                  class="riva-button riva-button-sm"
                  style="width: 110px"
                  *ngIf="props.edit && !selectedAddress"
                  (click)="onOpenAddressDialog(0)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-plus"
                    width="15"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#FFFFFF"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style="margin-right: 3px"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="12" y1="5" x2="12" y2="19" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                  New
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr
          class="mat-row"
          *ngFor="let element of customer.productionAddresses"
        >
          <td class="mat-cell">
            {{ element.fullAddress }}
            <span style="display: inline-block; padding-right: 5px">{{
              element.addressTypeLabel
            }}</span>
            <span *ngIf="isDefault(element)" class="set-default-button"
              >default</span
            >
            <span
              class="default-address"
              *ngIf="!isDefault(element)"
              (click)="onSetDefault(element)"
              >Set Default</span
            >
          </td>
          <td class="mat-cell">{{ element.phone }}</td>
          <td class="mat-cell">
            <div class="action-button" *ngIf="props.edit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-edit"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                (click)="onOpenAddressDialog(0, element)"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                />
                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                <line x1="16" y1="5" x2="19" y2="8" />
              </svg>
            </div>
          </td>
        </tr>
        <tr class="mat-row" *ngIf="!customer.productionAddresses.length">
          <td class="mat-cell" colspan="3" style="text-align: center">
            No Production Shipping Address
          </td>
        </tr>
        <tr class="mat-row">
          <td colspan="3" class="mat-cell address-header-row">
            <div class="address-header">
              <div>Development Shipping Address</div>
              <div>
                <button
                  class="riva-button riva-button-sm"
                  style="width: 110px"
                  *ngIf="props.edit && !selectedAddress"
                  (click)="onOpenAddressDialog(2)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-plus"
                    width="15"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#FFFFFF"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style="margin-right: 3px"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="12" y1="5" x2="12" y2="19" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                  New
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr
          class="mat-row"
          *ngFor="let element of customer.developmentAddresses"
        >
          <td class="mat-cell">
            {{ element.fullAddress }}
            <span style="display: inline-block; padding-right: 5px">{{
              element.addressTypeLabel
            }}</span>
            <span *ngIf="isDefault(element)" class="set-default-button"
              >default</span
            >
            <span
              class="default-address"
              *ngIf="!isDefault(element)"
              (click)="onSetDefault(element)"
              >Set Default</span
            >
          </td>
          <td class="mat-cell">{{ element.phone }}</td>
          <td class="mat-cell">
            <div class="action-button" *ngIf="props.edit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-edit"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                (click)="onOpenAddressDialog(2, element)"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                />
                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                <line x1="16" y1="5" x2="19" y2="8" />
              </svg>
            </div>
          </td>
        </tr>
        <tr class="mat-row" *ngIf="!customer.developmentAddresses.length">
          <td class="mat-cell" colspan="3" style="text-align: center">
            No Development Shipping Address
          </td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  style="display: grid; gap: 15px"
  [ngStyle]="{ 'grid-template-columns': props.edit ? 'repeat(2, 1fr)' : '1fr' }"
>
  <button
    class="btn btn-default"
    (click)="onClose()"
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
  >
    Cancel
  </button>
  <button
    *ngIf="props.edit"
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px"
    [disabled]="isSaving"
    (click)="onSave()"
  >
    Save
  </button>
</mat-dialog-actions>
