import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DateTime } from 'luxon';
import { EmployeeDownTime } from 'src/app/components/work-order-tracking/model';
import { User } from 'src/app/core/authentication/user';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { EmployeeDownTimeErrorDialogComponent } from './employee-down-time-error-dialog/employee-down-time-error-dialog.component';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'work-order-employee-down-time',
  templateUrl: './work-order-employee-down-time.component.html',
  styleUrls: ['./work-order-employee-down-time.component.scss'],
})
export class WorkOrderEmployeeDownTimeComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('employeeSort', { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild('workOrderSort', { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild('errorDialog')
  public readonly errorDialog!: SwalComponent;

  displayedColumnsForEmployee = ['selection', 'fullName', 'employeeID'];
  displayedColumnsForWorkOrder = [
    'scanInTime',
    'totalMinutesActive',
    'workDayDifference',
  ];
  accounts = new MatTableDataSource<User>([]);
  search = '';
  selectedUser: User | null = null;
  employeeDownTime = new MatTableDataSource<EmployeeDownTime>([]);
  isSelectedAll = false;
  isSomeSelected = false;
  isDownloadingFile = false;
  filters: Filters = {
    dateRangeFrom: DateTime.local().toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
  };
  isDateRange = false;

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.getUsers().subscribe((data = []) => {
      this.accounts.data = data
        .filter((a) => a.employeeID)
        .map((account) => ({
          ...account,
          fullName: this.getFullName(account),
        }));
    });
  }

  ngAfterViewInit(): void {
    this.accounts.sort = this.sort1;

    this.accounts.filterPredicate = (data: User, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.fullName
          ?.toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.employeeID?.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getFullName(account: User) {
    const name = [];
    account.firstName && name.push(account.firstName);
    account.lastName && name.push(account.lastName);
    if (!name.length) {
      name.push(account.userName);
    }
    return name.join(' ');
  }

  onFilterChange() {
    this.accounts.filter = JSON.stringify({ search: this.search });
  }

  onSelectUser(user: User) {
    this.selectedUser = user;
    this.getEmployeeDownTime(user.usersID);
  }

  getEmployeeDownTime(userId: number) {
    this.workOrderMovementService
      .getEmployeeDownTime(userId)
      .subscribe((data) => {
        this.employeeDownTime.data = data;
        this.employeeDownTime.sort = this.sort2;
      });
  }

  selectAll(checked) {
    this.accounts.data = this.accounts.data.map((e) => ({
      ...e,
      selected: checked,
    }));
    this.isSelectedAll = checked;
    this.isSomeSelected = false;
  }

  onSelect() {
    const selected = this.accounts.data.filter((i) => i.selected);
    this.isSelectedAll = selected.length === this.accounts.data.length;
    this.isSomeSelected = selected.length > 0 && !this.isSelectedAll;
  }

  onGenerateReport() {
    const usersId = this.accounts.data.reduce((accum, a) => {
      if (a.selected) return [...accum, a.usersID];
      return accum;
    }, []);
    this.isDownloadingFile = true;
    this.workOrderMovementService
      .getEmployeeDownTimeReport(
        usersId,
        this.filters.dateRangeFrom,
        this.filters.dateRangeTo,
      )
      .subscribe(
        (response) => {
          if (response == null) {
            setTimeout(() => {
              this.errorDialog.fire();
            }, 100);
            this.isDownloadingFile = false;
            return;
          }
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const selectedDate = DateTime.fromISO(this.filters.dateRangeFrom)
          a.download = `${selectedDate.toFormat("MM.dd.yyyy")} ${selectedDate.weekdayLong} Employee Down Time.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
          this.isDownloadingFile = false;
        },
        (employeeList) => {
          this.isDownloadingFile = false;
          this.dialog.open(EmployeeDownTimeErrorDialogComponent, {
            disableClose: true,
            maxWidth: '450px',
            width: '100%',
            data: {
              names: employeeList.reduce((accum, a) => {
                return [...accum, `${a.firstName} ${a.lastName}`];
              }, []),
            },
          });
        },
      );
  }

  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
  }
}
