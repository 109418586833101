<div class="riva-reporting">
  <div class="customer-section">
    <button
      class="riva-button"
      (click)="onFilterAllCustomer()"
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 26px;
      "
    >
      <span>Top 50 Products Total</span>
      <span>{{ totalOrderForProduct | number : '1.0' : 'en-US' }}</span>
    </button>
    <div class="table-container">
      <table
        mat-table
        multiTemplateDataRows
        matSort
        [dataSource]="productTotalOrders"
        matSortActive="totalQty"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="expander">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let element"
            class="expander-column"
            (click)="updateExpandedItem(element, $event)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-square-plus"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#a762b7"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              [ngClass]="{ hidden: expandedItem[element.productId] }"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
              />
              <path d="M9 12l6 0" />
              <path d="M12 9l0 6" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-square-minus"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#a762b7"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              [ngClass]="{ hidden: !expandedItem[element.productId] }"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
              />
              <path d="M9 12l6 0" />
            </svg>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="rowNumber">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            {{ element.rank }}
          </td>
        </ng-container>
        <ng-container matColumnDef="productName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by productName"
          >
            Product
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="product-row">
              <div
                style="
                  width: 50px;
                  padding: 5px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                "
              >
                <img
                  style="max-width: 100%; max-height: 100%"
                  [src]="element.picturePath"
                  onerror="this.src='assets/images/no-image.png'"
                />
              </div>
              <div>{{ element.productName }}</div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalQty">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by totalQty"
          >
            Total
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalQty | number : '1.0' : 'en-US' }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="gems-row"
          (click)="onTableRowClick(row)"
          [ngClass]="{
            'is-selected': row.productId === filters?.productId
          }"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="4">No Available Data</td>
        </tr>

        <ng-container matColumnDef="expandedDetail">
          <td
            class="expandable-detail"
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="expandable-element"
              [@detailExpand]="
                expandedItem[element.productId] ? 'expanded' : 'collapsed'
              "
            >
              <div class="expandable-container">
                <div></div>
                <div style="width: 100%">
                  <div class="container-row container-header">
                    <div class="container-column">Metal</div>
                    <div class="container-column">Qty</div>
                  </div>
                  <div
                    class="container-row"
                    *ngFor="let item of element.materials"
                    (click)="onSelectMetal(element.productId, item)"
                    [ngClass]="{
                      'is-selected':
                        element.productId === selectedMetal.productId &&
                        item.metal === selectedMetal.metal
                    }"
                  >
                    <div class="container-column">{{ item.metal }}</div>
                    <div class="container-column">{{ item.totalQty }}</div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr
          class="detail-row"
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
        ></tr>
      </table>
    </div>
  </div>
  <div>
    <div class="riva-reporting__filter">
      <div class="filters">
        <mat-form-field appearance="fill">
          <mat-label>Customer</mat-label>
          <mat-select [(value)]="filterByCustomer" (valueChange)="onFilter()">
            <mat-option [value]="0">All</mat-option>
            <mat-option
              *ngFor="let item of customers"
              [value]="item.custIdno"
              >{{ item.companyName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <riva-date-range-picker
          [startDate]="filters.dateRangeFrom"
          [endDate]="filters.dateRangeTo"
          (onChange)="onDateRangeChange($event)"
        ></riva-date-range-picker>
        <riva-reporting-presets
          (onChange)="onSetDateRange($event)"
        ></riva-reporting-presets>
      </div>
      <div class="view">
        <mat-button-toggle-group
          class="view-type-toggle"
          [(ngModel)]="viewType"
          (ngModelChange)="onFilter()"
        >
          <mat-button-toggle value="monthly" style="height: 55px; width: 50%"
            >Monthly</mat-button-toggle
          >
          <mat-button-toggle value="weekly" style="height: 55px; width: 50%"
            >Weekly</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="graph-section">
      <div class="product-type-filter">
        <h2 style="color: white">Filter by Type</h2>
        <div>
          <mat-checkbox
            [(ngModel)]="filters.product"
            (ngModelChange)="onFilterProductType()"
            size
            >Product</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            [(ngModel)]="filters.unfinishedChain"
            (ngModelChange)="onFilterProductType()"
            >Unfinished Chain</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            [(ngModel)]="filters.finishedChain"
            (ngModelChange)="onFilterProductType()"
            >Finished Chain</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            [(ngModel)]="filters.findings"
            (ngModelChange)="onFilterProductType()"
            >Findings</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            [(ngModel)]="filters.otherComponent"
            (ngModelChange)="onFilterProductType()"
            >Other Components</mat-checkbox
          >
        </div>
      </div>
      <div class="graph">
        <h1 style="color: white; margin: 0">Top 50 Products</h1>
        <canvas
          baseChart
          class="chart"
          [data]="lineChartData"
          [options]="lineChartOptions"
          [type]="lineChartType"
        ></canvas>
        <p style="color: white; margin: 0; margin-top: 10px; font-size: 16px">
          Data is based on the date orders are placed
        </p>
      </div>
    </div>
  </div>
</div>
