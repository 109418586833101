import { PAGE_NAME } from './pages';

export const CUSTOMER_FEATURE_KEY = {
  create: 'CUSTOMER_CREATE',
  edit: 'CUSTOMER_EDIT',
  view: 'CUSTOMER_VIEW',
};

export const customerPermission = {
  [PAGE_NAME.customer]: {
    SuperUser: {
      [CUSTOMER_FEATURE_KEY.create]: 'access',
      [CUSTOMER_FEATURE_KEY.edit]: 'access',
      [CUSTOMER_FEATURE_KEY.view]: 'hidden',
    },
    User: {
      [CUSTOMER_FEATURE_KEY.create]: 'access',
      [CUSTOMER_FEATURE_KEY.edit]: 'access',
      [CUSTOMER_FEATURE_KEY.view]: 'hidden',
    },
    Viewer: {
      [CUSTOMER_FEATURE_KEY.create]: 'disabled',
      [CUSTOMER_FEATURE_KEY.edit]: 'hidden',
      [CUSTOMER_FEATURE_KEY.view]: 'access',
    },
  },
};
