import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartConfiguration, ChartType } from 'chart.js';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { forkJoin } from 'rxjs';
import { Customers } from 'src/app/models/customer';
import {
  AnalyticsProductOrder,
  AnalyticsService,
} from 'src/app/services/analytics.service';
import { CustomerService } from 'src/app/services/customer.service';

interface ProductTotalOrder {
  productId: number;
  productName: string;
  productPicPath: string;
  totalQty: number;
}
interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
  productId?: number;
  product: boolean;
  unfinishedChain: boolean;
  finishedChain: boolean;
  findings: boolean;
  otherComponent: boolean;
}
@Component({
  selector: 'riva-reporting-top-product',
  templateUrl: './reporting-top-product.component.html',
  styleUrls: ['./reporting-top-product.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class ReportingTopProductComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  lineChartData: ChartConfiguration['data'];
  lineChartDataForQty: ChartConfiguration['data'];

  displayedColumns = ['expander', 'rowNumber', 'productName', 'totalQty'];

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      y: {
        position: 'left',
        grid: {
          color: '#68516d',
        },
        ticks: {
          color: '#68516d',
        },
      },
    },

    plugins: {
      legend: { display: true },
    },
  };
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  productTotalOrders = new MatTableDataSource<ProductTotalOrder>([]);
  productOrders: AnalyticsProductOrder[] = [];

  topProducts: AnalyticsProductOrder[] = [];
  topUnfinishedChains: AnalyticsProductOrder[] = [];
  topFinishedChains: AnalyticsProductOrder[] = [];
  topFindings: AnalyticsProductOrder[] = [];
  topOtherComponents: AnalyticsProductOrder[] = [];

  viewType: 'monthly' | 'weekly' = 'monthly';
  totalOrderForProduct = 0;
  customers: Customers[] = [];
  filterByCustomer: number = 0;

  filters: Filters = {
    dateRangeFrom: DateTime.local().minus({ months: 9 }).toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
    productId: 0,
    product: true,
    unfinishedChain: true,
    finishedChain: true,
    findings: true,
    otherComponent: true,
  };
  params = {
    PageNumber: 1,
    PageSize: 25,
    SearchQuery: '',
    CustomerId: 0,
  };
  selectedMetal: {
    metal?: string;
    productId?: number;
  } = {
    metal: '',
    productId: 0,
  };

  expandedItem: Record<number, boolean> = {};

  constructor(
    private analyticsService: AnalyticsService,
    private customerService: CustomerService,
  ) {}

  ngOnInit(): void {
    this.getTopProducts();
    this.customerService.getList().subscribe((data) => {
      this.customers = orderBy(data, 'companyName');
    });
  }
  ngAfterViewInit() {
    this.productTotalOrders.sort = this.sort;
  }

  updateExpandedItem(row: AnalyticsProductOrder, event) {
    event.stopPropagation();
    this.expandedItem[row.productId] = !this.expandedItem[row.productId];
  }

  onFilterProductType() {
    this.productOrders = [];
    if (this.filters.product) {
      this.productOrders = [...this.productOrders, ...this.topProducts];
    }
    if (this.filters.unfinishedChain) {
      this.productOrders = [...this.productOrders, ...this.topUnfinishedChains];
    }
    if (this.filters.finishedChain) {
      this.productOrders = [...this.productOrders, ...this.topFinishedChains];
    }
    if (this.filters.findings) {
      this.productOrders = [...this.productOrders, ...this.topFindings];
    }
    if (this.filters.otherComponent) {
      this.productOrders = [...this.productOrders, ...this.topOtherComponents];
    }
    this.onFilter();
  }

  onFilter() {
    const productIds =
      this.filters.productId > 0 ? [this.filters.productId] : [];
    const startDate = DateTime.fromISO(this.filters.dateRangeFrom);
    const endDate = DateTime.fromISO(this.filters.dateRangeTo).plus({ day: 1 });
    const filtered = this.productOrders.reduce((data, product) => {
      const orders = product.orderEntry.filter((i) => {
        const date = DateTime.fromISO(i.entryDate);
        const inRange = date >= startDate && date <= endDate;
        return inRange && (this.filterByCustomer === 0 || this.filterByCustomer === i.customerId);
      });
      return [
        ...data,
        {
          ...product,
          orderEntry: orders,
          totalQty: orders.reduce((total, i) => total + i.totalQty, 0),
        },
      ];
    }, []);
    const topProduct = orderBy(
      filtered.filter((p) => p.totalQty > 0),
      'totalQty',
      'desc',
    )
      .slice(0, 50)
      .map((p, i) => ({
        ...p,
        rank: i + 1,
      }));
    this.productTotalOrders.data = topProduct.reduce((all, product) => {
      const groupedMaterials = groupBy(product.orderEntry, (i) => i.metal);
      const materials = Object.keys(groupedMaterials).reduce((accum, key) => {
        const totalQty = groupedMaterials[key].reduce(
          (total, g) => total + g.totalQty,
          0,
        );
        return [
          ...accum,
          {
            metal: key,
            totalQty,
            orderEntry: groupedMaterials[key],
          },
        ];
      }, []);

      return [
        ...all,
        { ...product, materials: orderBy(materials, 'totalQty', 'desc') },
      ];
    }, []);
    this.totalOrderForProduct = this.productTotalOrders.data.reduce(
      (total, product) => total + product.totalQty,
      0,
    );
    const filteredData = filtered.filter(
      (product) =>
        productIds.length === 0 || productIds.includes(product.productId),
    );
    this.viewType === 'monthly'
      ? this.parseDataForTotalOrderGraphPerMonth(filteredData)
      : this.parseDataForTotalOrderGraphPerWeek(
          filteredData,
          startDate,
          endDate,
        );
  }

  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
    this.onFilter();
  }

  onSelectMetal(productId, selectedMetal) {
    this.selectedMetal.productId = productId;
    this.selectedMetal.metal = selectedMetal.metal;

    const startDate = DateTime.fromISO(this.filters.dateRangeFrom);
    const endDate = DateTime.fromISO(this.filters.dateRangeTo).plus({ day: 1 });
    if (this.viewType === 'monthly') {
      this.parseDataForTotalOrderGraphPerMonth([selectedMetal]);
    } else {
      this.parseDataForTotalOrderGraphPerWeek(
        [selectedMetal],
        startDate,
        endDate,
      );
    }
  }

  getTopProducts() {
    forkJoin([
      this.analyticsService.getAnalyticsTopProduct(),
      this.analyticsService.getAnalyticsTopUnfinishedChain(),
      this.analyticsService.getAnalyticsTopFinishedChain(),
      this.analyticsService.getAnalyticsTopFindings(),
      this.analyticsService.getAnalyticsTopOtherComponents(),
    ]).subscribe(
      ([
        products,
        unfinishedChains,
        finishedChains,
        findings,
        otherComponents,
      ]) => {
        this.topProducts = products ?? [];
        this.topUnfinishedChains = unfinishedChains ?? [];
        this.topFinishedChains = finishedChains ?? [];
        this.topFindings = findings ?? [];
        this.topOtherComponents = otherComponents ?? [];
        this.onFilterProductType();
      },
    );
  }

  parseDataForTotalOrderGraphPerWeek(data, startDate, endDate) {
    const allOrders = orderBy(
      data.reduce((accum, d) => {
        return [...accum, ...d.orderEntry];
      }, []),
      'entryDate',
    );
    const weekGroups = this.getWeeksInRange(startDate, endDate);
    allOrders.forEach((i) => {
      const currentGroupIndex = weekGroups.findIndex((g) => {
        const date = DateTime.fromISO(i.entryDate.split('T')[0]);
        const inRange = date >= g.startDate && date <= g.endDate;
        return inRange;
      });
      if (currentGroupIndex >= 0) {
        const group = weekGroups[currentGroupIndex];
        group.total = group.total + i.totalQty;
        weekGroups[currentGroupIndex] = group;
      }
    });
    let weekData = weekGroups.map((g) => g.total);
    let weekLabel = weekGroups.map((g) => g.label);

    this.lineChartData = {
      datasets: [
        {
          data: weekData,
          label: 'Top Products Per Week',
          backgroundColor: 'transparent',
          borderColor: '#9854a7',
          pointBackgroundColor: '#9854a7',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        },
      ],
      labels: weekLabel,
    };
  }

  parseDataForTotalOrderGraphPerMonth(data) {
    const mappedData = orderBy(
      data.reduce((accum, d) => {
        return [...accum, ...d.orderEntry];
      }, []),
      'entryDate',
    );
    const grouped = mappedData.map((i) => {
      const date = new Date(i.entryDate);
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      return { ...i, group: `${date.getMonth() + 1}-${month} ${year}` };
    });
    const monthRange = this.getMonthsInDateRange(
      this.filters.dateRangeFrom,
      this.filters.dateRangeTo,
    );
    const group = groupBy(grouped, (i) => i.group);
    let labels = Object.keys(monthRange).map((m) => m.split('-')[1]);
    const totalDataPerMonth = Object.keys(group).reduce(
      (totalPerMonth, key) => {
        totalPerMonth[key] = group[key].reduce(
          (total, x) => total + x.totalQty,
          0,
        );
        return totalPerMonth;
      },
      monthRange,
    );

    let values = Object.values(totalDataPerMonth) as number[];

    this.lineChartData = {
      datasets: [
        {
          data: values,
          label: 'Top Products Per Month',
          backgroundColor: 'transparent',
          borderColor: '#9854a7',
          pointBackgroundColor: '#9854a7',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        },
      ],
      labels,
    };
  }

  onTableRowClick(row) {
    this.selectedMetal.productId = 0;
    this.selectedMetal.metal = '';
    this.filters.productId = row.productId;
    this.onFilter();
  }
  onFilterAllCustomer() {
    this.filters.productId = 0;
    this.selectedMetal.metal = '';
    this.selectedMetal.productId = 0;
    this.onFilter();
  }
  onRangeChange(months?: number) {
    if (months == null) {
      this.filters.dateRangeFrom = `${DateTime.local().year}-01-01`;
      this.filters.dateRangeTo = DateTime.local().toFormat('yyyy-MM-dd');
    } else {
      this.filters.dateRangeFrom = DateTime.local()
        .minus({ months })
        .toFormat('yyyy-MM-dd');
      this.filters.dateRangeTo = DateTime.local().toFormat('yyyy-MM-dd');
    }
    this.onFilter();
  }
  getMonthsInDateRange(startDate, endDate) {
    let start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);
    const months = {};

    while (start <= end) {
      const monthName = start.toFormat('MMMM yyyy');
      const key = `${start.month}-${monthName}`;
      months[key] = 0;
      start = start.plus({ months: 1 });
    }

    return months;
  }
  getWeeksInRange(startDate, endDate) {
    const weeks = [];

    let currentWeekStart = startDate;

    while (currentWeekStart <= endDate) {
      const currentWeekEnd = currentWeekStart.plus({ days: 6 });

      const startDay = currentWeekStart.toFormat('d');
      const startMonth = currentWeekStart.toFormat('LLL');
      const startYear = currentWeekStart.toFormat('yyyy');

      const endDay = currentWeekEnd.toFormat('d');
      const endMonth = currentWeekEnd.toFormat('LLL');
      const endYear = currentWeekEnd.toFormat('yyyy');

      const weekRange = `${startMonth} ${startDay}${
        startYear !== endYear ? `, ${startYear}` : ''
      }-${startMonth !== endMonth ? `${endMonth} ` : ''}${endDay}, ${endYear}`;
      const range = {
        label: weekRange,
        startDate: currentWeekStart,
        endDate: currentWeekEnd,
        total: 0,
      };
      weeks.push(range);
      currentWeekStart = currentWeekEnd.plus({ days: 1 });
    }
    return weeks;
  }
  onSetDateRange(config) {
    this.filters.dateRangeFrom = config.from;
    this.filters.dateRangeTo = config.to;
    this.onFilter();
  }
}
