<div class="open-order-report">
  <div class="open-order-action">
    <div class="open-order-filter">
      <mat-form-field appearance="fill">
        <mat-label>Filter by customer</mat-label>
        <mat-select
          [(ngModel)]="filter.CustomerId"
          (ngModelChange)="getOpenOrders()"
        >
          <mat-option [value]="0" selected>ALL</mat-option>
          <mat-option
            *ngFor="let customer of customerList"
            [value]="customer.customerId"
          >
            {{ customer.customerName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Filter by order type</mat-label>
        <mat-select
          [formControl]="orderTypeControl"
          multiple
          (selectionChange)="onSelectionChange()"
        >
          <mat-option [value]="-1" selected>All</mat-option>
          <mat-option [value]="0">Standard</mat-option>
          <mat-option [value]="1">Internal</mat-option>
          <mat-option [value]="2">Online</mat-option>
          <mat-option [value]="3">Repair</mat-option>
        </mat-select>
      </mat-form-field>
      <riva-date-range-picker
        [startDate]="filter.ReceivedFrom"
        [endDate]="filter.ReceivedTo"
        startDateLabel="Received Start Date"
        endDateLabel="Received End Date"
        (onChange)="onDateRangeChange($event)"
      ></riva-date-range-picker>
      <riva-date-range-picker
        [startDate]="filter.DueDateFrom"
        [endDate]="filter.DueDateTo"
        [isSinglePicker]="true"
        singlePickerLabel="Due Cutoff"
        (onChange)="onChangeDueDate($event)"
      ></riva-date-range-picker>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Due cutoff</mat-label>
        <mat-select
          [(ngModel)]="filter.DueCutOff"
          (ngModelChange)="onChangeDueCutOff()"
        >
          <mat-option value="all">All</mat-option>
          <mat-option value="today">Due today</mat-option>
          <mat-option value="tomorrow-earlier"
            >Due tomorrow & Earlier</mat-option
          >
          <mat-option value="next-week-earlier"
            >Due next week & Earlier</mat-option
          >
        </mat-select>
      </mat-form-field> -->
      <mat-form-field appearance="fill">
        <mat-label>Sort by</mat-label>
        <mat-select [(ngModel)]="sortBy" (ngModelChange)="getOpenOrders()">
          <mat-option value="customername">Customer</mat-option>
          <mat-option value="duedate">Due Date</mat-option>
        </mat-select>
      </mat-form-field>
      <div style="padding-top: 10px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-sort-ascending"
          width="35"
          height="35"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          *ngIf="sortDirection === 'asc'"
          (click)="onSortChange()"
          style="cursor: pointer"
          nzTooltipTitle="Ascending"
          nzTooltipPlacement="bottom"
          nz-tooltip
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="6" x2="11" y2="6" />
          <line x1="4" y1="12" x2="11" y2="12" />
          <line x1="4" y1="18" x2="13" y2="18" />
          <polyline points="15 9 18 6 21 9" />
          <line x1="18" y1="6" x2="18" y2="18" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="sortDirection === 'desc'"
          class="icon icon-tabler icon-tabler-sort-descending"
          width="35"
          height="35"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          (click)="onSortChange()"
          style="cursor: pointer"
          nzTooltipTitle="Descending"
          nzTooltipPlacement="bottom"
          nz-tooltip
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="6" x2="13" y2="6" />
          <line x1="4" y1="12" x2="11" y2="12" />
          <line x1="4" y1="18" x2="11" y2="18" />
          <polyline points="15 15 18 18 21 15" />
          <line x1="18" y1="6" x2="18" y2="18" />
        </svg>
      </div>
      <mat-slide-toggle
        [(ngModel)]="filter.ShowOverdueOnly"
        (ngModelChange)="getOpenOrders()"
        style="color: white; margin-top: 16px; margin-left: 8px"
        >Show Late Order Only</mat-slide-toggle
      >
    </div>
    <button class="riva-button" style="width: 250px" (click)="onPrint()">
      Print
    </button>
  </div>
  <div class="loading-orders" [ngClass]="{ 'loading-hidden': !isLoading }">
    <div class="loading-spinner">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
      <h2 style="color: white; margin-top: 120px">Loading Orders...</h2>
    </div>
  </div>
  <div style="margin-top: 16px" [ngClass]="{ hidden: isLoading }">
    <div class="no-data-available" *ngIf="openOrders.length === 0">
      No Data Available
    </div>
    <div class="open-order-summary-container" *ngIf="openOrders.length > 0">
      <div class="label">Total Back Order</div>
      <div class="value">{{ totalBackOrder | number : '1.0-0' }}</div>
    </div>
    <div class="open-order-canvas">
      <div class="open-order-container" id="print-section">
        <div class="open-order-item" *ngFor="let item of openOrders">
          <div>
            <div class="image-container">
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="item.picturePath"
                onerror="this.src = 'assets/images/no-image.png'"
              />
            </div>
          </div>
          <div class="open-order-details">
            <div class="open-order-summary">
              <div>Name:</div>
              <div>{{ item.productName }}</div>
            </div>
            <div class="open-order-summary">
              <div>Total On BO:</div>
              <div>{{ item.totalBackOrder }}</div>
            </div>
            <div class="open-order-items">
              <table>
                <tr>
                  <th class="td-workorder">Work Order #</th>
                  <th class="td-po">PO #</th>
                  <th class="td-backorder">Back Order #</th>
                  <th class="td-customer">Customer</th>
                  <th class="td-duedate">Due Date</th>
                  <th class="td-metal">Metal</th>
                  <th class="td-size">Size</th>
                  <th class="td-stone">Stone Type</th>
                </tr>
                <tr *ngFor="let order of item.items">
                  <td>
                    <a
                      *ngIf="order.workOrdersId"
                      class="link"
                      (click)="onPrintWorkOrder(order)"
                      >{{ order.workOrdersId }}</a
                    >
                  </td>
                  <td>
                    <a [routerLink]="order.link">
                      <span>{{ order.poExternal || order.poInternal }}</span>
                      <!-- <span *ngIf="order.poInternal && order.poExternal">
                        /
                      </span>
                      <span>{{ order.poExternal }}</span> -->
                    </a>
                  </td>
                  <td>{{ order.backOrder }}</td>
                  <td>
                    {{ order.customerName }}
                  </td>
                  <td>
                    {{ order.dueDate | date : 'MM/dd/yyyy' }}
                  </td>
                  <td>
                    <div
                      *ngIf="
                        order.multiPrimaryMaterialColor &&
                        order.multiSecondaryMaterialColor
                      "
                      style="display: flex; align-items: center; gap: 1px"
                    >
                      <div class="multi-material">
                        <div
                          class="primary-material"
                          [ngStyle]="{
                            'border-color':
                              order.multiPrimaryMaterialColor +
                              ' transparent transparent transparent'
                          }"
                        ></div>
                        <div
                          class="secondary-material"
                          [ngStyle]="{
                            'border-color':
                              'transparent transparent' +
                              order.multiSecondaryMaterialColor +
                              ' transparent'
                          }"
                        ></div>
                      </div>
                      <span>{{ order.materialCode }}</span>
                    </div>
                    <div
                      style="display: flex; align-items: center"
                      *ngIf="
                        !(
                          order.multiPrimaryMaterialColor &&
                          order.multiSecondaryMaterialColor
                        )
                      "
                    >
                      <div
                        *ngIf="order.materialPrintColor1Hex"
                        style="border: 3px solid black; margin-right: 10px"
                      >
                        <div
                          style="
                            width: 53px;
                            height: 0;
                            border-top: 26px solid white;
                            background-color: rgb(255, 255, 255);
                          "
                          [ngStyle]="{
                            'border-color': order.materialPrintColor1Hex
                          }"
                        ></div>
                      </div>
                      {{ order.materialCode }}
                    </div>
                  </td>
                  <td>{{ order.size }}</td>
                  <td>{{ order.stoneType }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
